import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { EmailTemplatesFormData } from 'app/pages/Admin/Email/EmailTemplates';

export const getAdminDefaultEmailTemplatesCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.EMAIL.TEMPLATES,
    },
    true,
  );
};

export const updateAdminDefaultEmailTemplatesCall = async (data: EmailTemplatesFormData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ADMIN.EMAIL.TEMPLATES,
      data,
    },
    true,
  );
};
