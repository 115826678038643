import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

import { TimeManagementRange, CustomBookingLength } from 'types/app/timeManagement';

export interface UpdateBookingLengthData {
  ranges: TimeManagementRange[];
}

export const updateDefaultBookingLengthCall = async (data: UpdateBookingLengthData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.DEFAULT_BOOKING_LENGTH,
    },
    true,
  );
};

export const getDefaultBookingLengthCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.DEFAULT_BOOKING_LENGTH,
    },
    true,
  );
};

export const updateCustomBookingLengthOrderCall = async (data: number[]) => {
  return await asyncRequest(
    {
      method: 'POST',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH_ORDER,
    },
    true,
  );
};

export const getCustomBookingLengthCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH,
    },
    true,
  );
};

export const getCustomBookingLengthByIdCall = async (id: number) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH}/${id}`,
    },
    true,
  );
};

export const createCustomBookingLengthCall = async (data: CustomBookingLength) => {
  return await asyncRequest(
    {
      method: 'POST',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH,
    },
    true,
  );
};

export const updateCustomBookingLengthByIdCall = async (data: CustomBookingLength, id: number) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH}/${id}`,
    },
    true,
  );
};

export const deleteCustomBookingLengthByIdCall = async (id: number) => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.TIME_MANAGEMENT.CUSTOM_BOOKING_LENGTH}/${id}`,
    },
    true,
  );
};
