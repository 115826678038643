import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { EmailSettingsFormData } from 'app/pages/RestaurantSettings/Notifications/EmailSettings';
import { EmailTemplateFieldData } from 'app/pages/RestaurantSettings/Notifications/EmailSettings/_components/EmailTemplates/_types/EmailTemplatesFields';
import { FeedbackSettingsFormData } from 'app/pages/RestaurantSettings/Notifications/FeedbackSettings';

export const getNotificationSMSSettingsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.SMS,
    },
    true,
  );
};

export const getNotificationEmailSettingsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.EMAIL,
    },
    true,
  );
};

export const updateNotificationEmailSettingsCall = async (data: EmailSettingsFormData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.EMAIL,
      data,
    },
    true,
  );
};

export const emailPreviewCall = async (
  template: string,
  data: EmailTemplateFieldData,
  language: string,
) => {
  return await asyncRequest(
    {
      method: 'POST',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.EMAIL_PREVIEW}/${template}`,
      data: { data, language },
    },
    true,
  );
};

export const defaultNotificationEmailTemplateCall = async (language: string, template: string) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.DEFAULT_TEMPLATE}/${language}/${template}`,
    },
    true,
  );
};

export const getNotificationFeedbackSettingsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.FEEDBACK,
    },
    true,
  );
};

export const updateNotificationFeedbackSettingsCall = async (data: FeedbackSettingsFormData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.NOTIFICATION.FEEDBACK,
      data,
    },
    true,
  );
};
