import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { WidgetIntegrationType } from 'types/app/widgetIntegrations';

export const fetchWidgetIntegrationsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_INTEGRATIONS,
    },
    true,
  );
};

export const fetchOneWidgetIntegrationCall = async (id: number) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_INTEGRATIONS}/${id}`,
    },
    true,
  );
};

export const createWidgetIntegrationCall = async (data: WidgetIntegrationType) => {
  return await asyncRequest(
    {
      method: 'POST',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_INTEGRATIONS,
      data,
    },
    true,
  );
};

export const updateWidgetIntegrationCall = async (data: WidgetIntegrationType) => {
  return await asyncRequest(
    {
      method: 'PUT',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_INTEGRATIONS}/${data.id}`,
      data,
    },
    true,
  );
};

export const deleteWidgetIntegrationCall = async (id: number) => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_INTEGRATIONS}/${id}`,
    },
    true,
  );
};
