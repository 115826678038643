import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { AvailableLanguages } from 'enums/availableLanguages';
import { Descendant } from 'slate';

export interface IPoliciesTermsData {
  terms: string[];
  cancellation_policy: {
    [key: string]: {
      language: AvailableLanguages;
      body: Descendant[];
    };
  };
}

export const getPoliciesTermsCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.POLICIES_AND_TERMS,
    },
    true,
  );
};

export const updatePoliciesTermsCall = async (data: IPoliciesTermsData) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.POLICIES_AND_TERMS,
    },
    true,
  );
};
