import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

export const getOwnerCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ACCOUNT.OWNER.ROOT,
    },
    true,
  );
};

export const updateOwnerCall = async (data: any) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ACCOUNT.OWNER.ROOT,
    },
    true,
  );
};
