import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';

type WidgetFields = {
  maximum_guests_message: string;
  allergies: {
    status: boolean;
    required: boolean;
  };
  wine_pairing: {
    status: boolean;
    required: boolean;
  };
  post_code: {
    status: boolean;
    required: boolean;
  };
  voucher: boolean;
  adults_kids: {
    status: boolean;
    required: boolean;
  };
  high_chairs: {
    status: boolean;
    required: boolean;
  };
  special_occasion: {
    status: boolean;
    required: boolean;
  };
  company_name: {
    status: boolean;
    required: boolean;
  };
  quick_tags: string[];
  newsletter_field: boolean;
  newsletter_message: string;
  email_not_required: boolean;
  comment_field: boolean;
};

export type WidgetFieldsResponse = WidgetFields;

export type UpdateWidgetFieldsRequest = WidgetFields;

export const getWidgetFieldsCall = async (): Promise<WidgetFieldsResponse> => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_FIELDS,
    },
    true,
  );
};

export const updateWidgetFieldsCall = async (data: UpdateWidgetFieldsRequest) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.RESTAURANT.SETTINGS.WIDGET_FIELDS,
    },
    true,
  );
};
