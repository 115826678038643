import { asyncRequest, baseUrl } from 'utils/http/api';
import { ENDPOINT_URLS } from 'constants/endpointUrls';
import { IUser } from 'types/app/users';

export const getUsersCall = async () => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ACCOUNT.USER.USERS,
    },
    true,
  );
};

export const getUserCall = async (userId: number) => {
  return await asyncRequest(
    {
      method: 'GET',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ACCOUNT.USER.ROOT}/${userId}`,
    },
    true,
  );
};

export const createUserCall = async (data: IUser) => {
  return await asyncRequest(
    {
      method: 'POST',
      data,
      baseURL: baseUrl,
      url: ENDPOINT_URLS.ACCOUNT.USER.ROOT,
    },
    true,
  );
};

export const updateUserCall = async (data: IUser, userId: number) => {
  return await asyncRequest(
    {
      method: 'PUT',
      data,
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ACCOUNT.USER.ROOT}/${userId}`,
    },
    true,
  );
};

export const deleteUserCall = async (userId: number) => {
  return await asyncRequest(
    {
      method: 'DELETE',
      baseURL: baseUrl,
      url: `${ENDPOINT_URLS.ACCOUNT.USER.ROOT}/${userId}`,
    },
    true,
  );
};
